import useDisclosure from "@/hooks/useDisclosure";
import { RootState } from "@/stores/rootReducer";
import { ReactNode, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { DialogSignIn } from "../components/DialogSignIn";
import useModalLimitFunction from "@/features/user/hooks/useModalLimitFunction";
import useUserStatus from "@/features/user/hooks/useUserStatus";

interface UseSignInRequiredActionArgs {
  action: () => void;
  message?: ReactNode;
  addCurrentPathToSignUpPath?: boolean;
  requiredIsPro?: boolean;
}

interface UseSignInRequiredActionReturn {
  executeAction: () => void;
  SignInModalComponent: JSX.Element;
}

const useSignInRequiredAction = ({
  action,
  message,
  addCurrentPathToSignUpPath = true,
  requiredIsPro = false,
}: UseSignInRequiredActionArgs): UseSignInRequiredActionReturn => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { isOpen, closeHandler, openHandler } = useDisclosure();

  const { open } = useModalLimitFunction();
  const { isDesigner } = useUserStatus();

  const executeAction = useCallback(() => {
    // ログインしていない場合は、ログインモーダルを表示する
    if (!isAuthenticated) {
      openHandler();

      return;
    }

    // プロアカウント必須の場合は、アカウント切り替えモーダルを表示する
    if (requiredIsPro && !isDesigner) {
      open();

      return;
    }

    action();
  }, [action, isAuthenticated, openHandler, requiredIsPro, isDesigner, open]);

  // ログイン後の処理
  const didLoginHandler = useCallback(() => {
    closeHandler();

    if (requiredIsPro && !isDesigner) {
      open();

      return;
    }

    action();
  }, [open, action, requiredIsPro, closeHandler, isDesigner]);

  const SignInModalComponent = useMemo<JSX.Element>(() => {
    return (
      <DialogSignIn
        isOpen={isOpen}
        close={closeHandler}
        message={message}
        onDidLogin={didLoginHandler}
        addCurrentPathToSignUpPath={addCurrentPathToSignUpPath}
      />
    );
  }, [
    isOpen,
    closeHandler,
    message,
    addCurrentPathToSignUpPath,
    didLoginHandler,
  ]);

  return {
    executeAction,
    SignInModalComponent,
  };
};

export { useSignInRequiredAction };
