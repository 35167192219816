import { RedirectReason } from "@/domain/values/RedirectReason";

export const getReasonText = (reason: RedirectReason): string => {
  switch (reason) {
    case RedirectReason.None:
      return "";

    case RedirectReason.Follow:
      return "フォローするには、ログインをしてください";

    case RedirectReason.AddList:
      return "リストに追加するには、ログインをしてください";

    case RedirectReason.CreateProject:
      return "プロジェクトを投稿するには、ログインをしてください";

    case RedirectReason.Like:
      return "プロジェクトにLikeをつけるには、ログインをしてください";

    case RedirectReason.Order:
      return "サンプルの注文を続けるには、ログインをしてください";

    case RedirectReason.ChangeEmail:
      return "メールアドレスを変更するには、再ログインしてください";

    case RedirectReason.ChangePassword:
      return "パスワードを変更するには、再ログインしてください";

    case RedirectReason.ContactMaker:
      return "メーカーへのお問い合わせをするには、ログインしてください";

    case RedirectReason.AddToCart:
      return "カートにサンプルを追加するには、ログインをしてください";

    case RedirectReason.ShowInCart:
      return "カートを確認するには、ログインをしてください";
  }
};
